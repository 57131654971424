<template>
  <div class="baseConsoleTablePage hasTopButton">
    <div class="pageTopButton">
      <template v-if="generationBinding && company_status != 2">
        <el-button 
          type="primary" 
          @click="handleAdd" 
          size="small"
          >添加开票信息
        </el-button>
      </template>
      <template v-else>
        <el-button 
          type="primary" 
          @click="handleAdd" 
          size="small"
          v-if="inspectButton('cos:mak:add')"
          >添加开票信息
        </el-button>
      </template>
    </div>
    <div  class="tableBlock">
      <el-table  :data="tableData" height="100" v-tableHeight="{ bottomOffset: 74 }"
        :tableLoading="loading" style="width: 100%">
        <el-table-column prop="title" label="开票抬头" width="120" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="itin" label="纳税人识别号" width="150"  show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="address" label="开票地址"> </el-table-column>
        <el-table-column prop="phone" label="电话"> </el-table-column>
        <el-table-column  label="开票银行账户"  width="250" >
          <div slot-scope="scope">
              <div v-if="scope.row.is_new">
                <el-popover
                  placement="right"
                  width="700"
                  trigger="click">
                  <el-table :data="scope.row.bank">
                    <el-table-column width="80" property="code" label="币种"></el-table-column>
                    <el-table-column width="250" property="bank_name" label="银行名称"></el-table-column>
                    <el-table-column width="250" property="bank_num" label="银行账号"></el-table-column>
                    <el-table-column property="is_default" label="是否默认">
                        <span slot-scope="scope">{{ scope.row.is_default?'默认':"" }}</span>
                    </el-table-column>
                  </el-table>
                  <div slot="reference" class="bank">
                    <span  style="color:#808080">{{scope.row.bank|bank_filter}}</span>
                    <span class="el-icon-arrow-right"></span>
                  </div>
                </el-popover>
              </div>
              <div v-else  style="color: #808080">{{scope.row.bank|textFormat}}</div>
          </div>
        </el-table-column>
        <el-table-column prop="email" :label="$t('email')"> </el-table-column>
        <el-table-column label="纸质发票收件地址" width="150px"  show-overflow-tooltip>
          <span slot-scope="scope">{{ scope.row.consignee_address|textFormat }}</span>
        </el-table-column>
        <el-table-column  label="发票收件人"  width="150px"  show-overflow-tooltip>
          <span slot-scope="scope">{{ scope.row.recipients|textFormat }}</span>
        </el-table-column>
        <el-table-column label="发票收件电话"  width="150px"  show-overflow-tooltip>
          <span slot-scope="scope">{{ scope.row.consignee_phone|textFormat }}</span>
        </el-table-column>
        <el-table-column  label="是否默认">
          <span slot-scope="scope">
            <span v-if="scope.row.is_default"   style="color: #808080" >默认</span>
          </span>
        </el-table-column>
        <el-table-column label="操作" fixed="right" v-if="company_status != 2">
          <template slot-scope="scope">
            <el-button
              @click="handleClick(scope.row)"
              :disabled="scope.row.is_default ? true : false"
              type="text"
              size="small"
              >设为默认</el-button>
            <el-button
              @click="handleEdit(scope.row)"
              type="text"
              size="small"
              >编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    
    <el-dialog
      :title="current_row?'编辑开票信息':'添加开票信息'"
      width="900px"
      :visible.sync="dialogFormVisible"
      @close="close"
    >
      <el-form v-if='dialogFormVisible' :model="form" ref="ruleForm" :rules="rules">
        <div class="itemBox">
            <el-form-item
              label="开票抬头"
              label-width="160px"
              required
              :show-message="false"
              prop="title"
              class="tips_position"
            >
              <el-input size='small' :disabled="isEdit" v-model="form.title" autocomplete="off"></el-input>
              <div class="mytips">添加后不可修改，请谨慎填写。</div>
            </el-form-item>
            <el-form-item
              label="纳税人识别号"
              label-width="160px"
              required
              :show-message="false"
              prop="itin"
              class="tips_position"
            >
              <el-input size='small' :disabled="isEdit" v-model="form.itin" autocomplete="off"></el-input>
              <div class="mytips">添加后不可修改，请谨慎填写。</div>
            </el-form-item>
        </div>
        <div class="itemBox">
            <el-form-item
              label="开票地址"
              label-width="160px"
              required
              :show-message="false"
              prop="address"
            >
              <el-input size='small' v-model="form.address" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item
              label="电话"
              label-width="160px"
              required
              :show-message="false"
              prop="phone"
            >
              <el-input size='small' v-model="form.phone" autocomplete="off"></el-input>
            </el-form-item>
        </div>
        <el-form-item
          label="开票银行"
          label-width="160px"
          required
          prop="bank"
          :show-message="false"
        >
          <el-button size='mini' type='primary' @click="handle_add_bank">添加开户银行</el-button>
          <span style="height: 12px;color: #F56C6C;top:27px;font-size: 10px;display: inline-block;padding-left:15px">添加后不可修改，请谨慎填写。</span>
        </el-form-item>
        <div class="bank_box" v-if="form.bank">
            <div>
              <p class="t1">币种</p>
              <p class="t2">银行名称</p>
              <p class="t3">银行账号</p>
              <p class="t4">是否默认</p>
              <p class="t5"></p>
            </div>
            <div v-for="(item,index) in form.bank" :key="index" style="padding-bottom:10px">
              <div class="t1">
                  <el-select  v-if="isEdit&&item.edit || !isEdit" v-model="form.bank[index].code" size='mini' placeholder="请选择" style="width:90px" >
                    <el-option
                      v-for="(it,i) in codeArr"
                      :key="i"
                      :label="it.code"
                      :value="it.code">
                    </el-option>
                  </el-select>
                  <span v-else>{{item.code}}</span>
              </div>
              <div class="t2">
                <el-input v-if="isEdit&&item.edit || !isEdit" v-model="form.bank[index].bank_name" size="mini" placeholder="需包含支行信息"></el-input>
                <span v-else>{{item.bank_name}}</span>
              </div>
              <div class="t3">
                <el-input v-if="isEdit&&item.edit || !isEdit"  v-model="form.bank[index].bank_num" size="mini" placeholder=""></el-input>
                <span v-else>{{item.bank_num}}</span>
              </div>
              <div class="t4" style="padding-left:10px">
                <el-checkbox v-model="form.bank[index].is_default" :disabled="form.bank[index].is_default" @change="checked=>is_default_change(checked,index)"></el-checkbox>
              </div>
              <div class="t5">
                <!-- <el-button type="text" size="mini" v-if="isEdit" @click="form.bank[index].edit=true">编辑</el-button> -->
                <el-button type="text" size="mini" :disabled="form.bank.length==1||form.bank[index].is_default" @click="bank_item_cancle(index)">删除</el-button>
              </div>
            </div>
        </div>
        <div class="itemBox">
            <el-form-item
             :label="$t('email')"
              label-width="160px"
              required
              :show-message="false"
              prop="email"
            >
              <el-input size='small' v-model="form.email" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item
              label="纸质发票收件地址"
              label-width="160px"
              required
              :show-message="false"
              prop="consignee_address"
            >
              <el-input size='small' v-model="form.consignee_address" autocomplete="off"></el-input>
            </el-form-item>
        </div>
        <div class="itemBox">
          <el-form-item
            label="发票接收人"
            label-width="160px"
            required
            :show-message="false"
            prop="recipients"
          >
            <el-input size='small' v-model="form.recipients" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item
            label="发票收件电话"
            label-width="160px"
            required
            :show-message="false"
            prop="consignee_phone"
          >
            <el-input size='small' v-model="form.consignee_phone" autocomplete="off"></el-input>
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button  size='small' @click="dialogFormVisible = false">取 消</el-button>
        <el-button  size='small' type="primary" @click="submitForm('ruleForm')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    generationBinding: {
      type: Boolean,
      default: false
    },
    bind_company_id: {
      type: String | Number,
      default: ''
    }
  },
  data() {
    return {
      form: {
        title: "",
        itin: "",
        address: "",
        phone: "",
        email: "",
        recipients:"",//发票接收人
        consignee_address:"",//纸质发票收件地址
        consignee_phone:"",//发票接受电话
        bank:[
          {
            code:"",
            bank_name:"",
            bank_num:"",
            is_default:true,
          }
        ]
      },
      rules: {},
      loading:false,
      dialogFormVisible: false,
      tableData: [],
      isEdit:false,
      current_row:null,
      codeArr:[],
      company_status:""
    };
  },
  mounted() {
    if(this.PJID == 'YMP'){
      this.$log({
        type: 1,
        topic: "ymp.workbench",
        id: "ymp.workbench.Invoice",
        content: JSON.stringify({
          company_id: this.USER_INFO.company_id || ''
        }),
      });
    }
    this.$nextTick(()=>{
      this.getList();
    })
    this.company_status = this.$route.query.company_status
    this.getCodeList()
  },
  watch:{
    "form.bank":function(value){
      console.log('vvvvvvvvvv',value);
    }
  },
  filters:{
    bank_filter(arr){
      let obj=arr.find(item=>{
        return item.is_default==true
      })
      if(obj){
        return `${obj.code}:${obj.bank_name}:${obj.bank_num}`
      }else{
        return '-'
      }
      
    }
  },
  methods: {
    close() {
      this.form = {
        title: "",
        itin: "",
        address: "",
        phone: "",
        bank: "",
        email: "",
        recipients:"",//发票接收人
        consignee_address:"",//纸质发票收件地址
        consignee_phone:"",//发票接受电话
      };
    },
    //  
    async getCodeList(){
      let res=await this.$store.dispatch("baseConsole/getcurrencyList")
      this.codeArr=res.data
    },
    // 设置默认
    async handleClick(row) {
      if(!this.generationBinding){// 社会自己公司时记录日志
        if(this.PJID == 'YMP'){
        this.$log({
          type: 1,
          topic: "ymp.workbench",
          id: "ymp.workbench.Invoice.normal",
          content: JSON.stringify({
            company_id: this.USER_INFO.company_id || '',
            id: row.id || ''
          }),
        });}
      }
      let data = await this.$store.dispatch("baseConsole/addinvoiceMsg", {
        id: row.id,
        is_default: 1,
        company_id: this.generationBinding ? this.bind_company_id: this.USER_INFO.company_id,
      });
      if (data.success) {
        this.$message({
          message: "设置成功",
          type: "success",
        });
        this.getList();
      }
    },
    // 打开添加开篇信息弹框
    handleAdd(){
      if(this.PJID == 'YMP'){
        this.$log({
          type: 1,
          topic: "ymp.workbench",
          id: "ymp.workbench.Invoice.add",
          content: JSON.stringify({
            company_id: this.USER_INFO.company_id || ''
          }),
        });
      }
      this.current_row=null
      this.dialogFormVisible = true
      this.isEdit=false
      this.form.bank=[
        {
          code:"",
          bank_name:"",
          bank_num:"",
          is_default:true,
        }
      ]
    },
    // 添加一条银行信息
    handle_add_bank(){
      this.form.bank.push({
        code:"",
        bank_name:"",
        bank_num:"",
        is_default:false,
        edit:true,
      })
    },
    // 修改银行信息的默认
    is_default_change(checked,index){
      this.form.bank.map((item,i)=>{
        if(i==index){
          item.is_default=true
        }else{
          item.is_default=false
        }
        return item
      })
    },
    // 删除一条银行信息
    bank_item_cancle(index){
      this.form.bank.splice(index,1)
    },
    // 编辑
    handleEdit(row){
      if(!this.generationBinding){// 设置自己公司时记录日志
        if(this.PJID == 'YMP'){
        this.$log({
          type: 1,
          topic: "ymp.workbench",
          id: "ymp.workbench.Invoice.edit",
          content: JSON.stringify({
            company_id: this.USER_INFO.company_id || '',
            id: row.id || ''
          }),
        });}
      }
      row=JSON.parse(JSON.stringify(row))
      this.current_row=row
      this.form = {
        id:row.id,
        title: row.title,
        itin: row.itin,
        address:  row.address,
        phone:  row.phone,
        bank:  row.bank,
        email:  row.email,
        recipients:row.recipients,//发票接收人
        consignee_address:row.consignee_address,//纸质发票收件地址
        consignee_phone:row.consignee_phone,//发票接受电话
      };
      if(row.is_new==false){
        this.form.bank=[
          {
            code:"",
            bank_name:row.bank,
            bank_num:"",
            is_default:true,
            edit:true,
          }
        ]
      }else{
        this.form.bank=row.bank
      }
      this.isEdit=true
      this.dialogFormVisible = true
        
    },
    // 验证开票银行不为为空
    bankMsg_empty(){
      let not_empty=false
      let emp=this.form.bank.find(item=>{
        return !item.code || !item.bank_name || !item.bank_num
      })
      if(emp){
        not_empty=true
      }
      return not_empty
    },
    // 编辑  添加  --->提交
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if(this.bankMsg_empty()){
            this.$message.warning('银行信息不能为空')
            return
          }
          this.dialogFormVisible = false;
          let params = this.form;
          params.company_id = this.generationBinding ? this.bind_company_id: this.USER_INFO.company_id;
          let bankMsg=this.form.bank
          bankMsg=JSON.parse(JSON.stringify(bankMsg))
          if(this.isEdit){
            bankMsg.map(item=>{
              delete item.edit
            })
          }
          params.bank = JSON.stringify(bankMsg)
          let data = await this.$store.dispatch(
            "baseConsole/addinvoiceMsg",
            params
          );
          this.dialogFormVisible = false;
          if (data.success) {
            this.$message({
              message:this.isEdit?'编辑成功':"添加成功",
              type: "success",
            });
            
            this.getList();
          }
        } else {
          return false;
        }
      });
    },
    async getList() {
      this.loading=true
      let res = await this.$store.dispatch("baseConsole/getinvoiceList", {
        company_id: this.generationBinding ? this.bind_company_id: this.USER_INFO.company_id,
        nopage:1
      });
      let arr= res.data.map(item=>{
        try {
          var obj=JSON.parse(item.bank)[0].bank_name;
          item.is_new=true
          item.bank=JSON.parse(item.bank)
          item.bank.map(item=>{
            item.edit=false
            return item
          })
        } catch(e) {
          item.is_new=false
        }
        return item
      })
      console.log(arr);
      this.tableData = arr
      this.loading=false
    },
  },
};
</script>

<style lang="less" scoped>
  /deep/ .el-dialog__body{
    padding-bottom: 0;
  }
  .tips_position{
    position: relative;
    .mytips{
      position: absolute;
      height: 12px;
      color: #F56C6C;
      top:27px;
      font-size: 10px;
    }
  }
  .itemBox{
    display: flex;
    justify-content: space-between;
  }
  .bank{
    display: flex;
    align-items: center;
    cursor: pointer;
    span:nth-child(2){
      padding-left: 10px;
    }
  }
  .bank_box{
    padding-left:160px; 
    padding-bottom:20px;
    >div{
      display: flex;
      .t1{
        width: 100px;
        padding-right: 10px;
      }
      .t2{
        width: 200px;
        padding-right: 10px;
      }
      .t3{
        width: 200px;
        padding-right: 10px;
      }
      .t4{
        width: 100px;
        padding-right: 10px;
      }
      .t5{
        width: 100px;
        padding-right: 10px;
      }
    }
    >div>p{
      padding-bottom: 10px;
    }
    >div>div{
      display: flex;
      align-items: center;
    }
  }
  .pageBok {
    // padding: 30px;
  }
  .pageTopButton{
    min-height: 32px;
  }
</style>
